import { Link } from 'react-router-dom';
import logo from '../assets/icons/logo-text.png';

const Footer = () => {
  return (
    <section className='container lg:w-[98%] md:w-[96%] mx-auto my-8 '>
      <div className='flex flex-1 items-center px-3 md:px-3'>
        <Link to='/'>
          <img className='h-8 w-auto my-1 ' src={logo} alt='COLE 4 STEEZE' />
        </Link>
      </div>
    </section>
  );
};

export default Footer;
