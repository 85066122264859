import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import logo from '../assets/icons/logo-text.png';

export default function Navigate() {
  return (
    <Disclosure
      as='nav'
      className='z-10 fixed w-full bg-white pt-0 md:shadow-xl'>
      {({ open, close }) => (
        <section className='container lg:w-[98%] md:w-[96%] mx-auto'>
          <div className='px-2 md:px-0'>
            <div className='relative flex h-20 items-center justify-between'>
              <div className='flex flex-1 items-center'>
                <Link to='/'>
                  <img
                    className='h-8 w-auto my-1'
                    src={logo}
                    alt='COLE 4 STEEZE'
                  />
                </Link>
              </div>

              <div className='flex flex-shrink-0 items-center justify-center sm:items-stretch sm:justify-start '>
                <button className='border-2 border-black md:hover:bg-black bg-black md:bg-transparent md:hover:border-white md:hover:text-white text-white md:rounded-3xl  rounded-xl font-medium md:text-black mx-auto md:px-7 px-4 py-2 '>
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </Disclosure>
  );
}
