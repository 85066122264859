import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import TransText from "../assets/transtext.png";

import Banner from "./Banner";
import Core from "./Core";
import FAQ from "./FAQ";
import Diversity from "./Diversity";

const index = () => {
  return (
    <>
      <Banner />
      <Core />
      <Diversity />
      <FAQ />
      <section className='md:p-10 px-3 py-8 mt-24 text-center bg-[#0D5260] md:h-[100vh]'>
        <div className='mt-8'>
          <h1 className='md:text-5xl text-xl font-semibold tracking-wider text-[#C1F4FF]'>
            Understanding Beyond Words
          </h1>
          <p className='md:mt-6 mt-2 text-[#A3C5CC]'>
            Our advanced AI grasps the intricacies of tonal languages, including
            the subtle variations in <br className='hidden md:block' /> pitch
            that can significantly impact meaning
          </p>
          <button className='border-2 border-[#C1F4FF] md:hover:bg-[#C1F4FF] bg-[#C1F4FF] md:bg-transparent md:hover:border-[#148297] md:hover:text-[#148297] text-[#148297] rounded-2xl md:px-12 md:py-6 py-4 px-6 md:font-semibold font-medium mt-14 md:text-2xl text-xl md:text-[#C1F4FF] mx-auto flex '>
            Get early access{' '}
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>

          <img
            src={TransText}
            alt=''
            className='md:w-11/12 mx-auto mt-20 mb-12'
          />

          <p className='text-xs md:w-9/12 mx-auto text-[#8FADB2]'>
            While these tonal differences are crucial in speech, standard
            written Hausa doesn't usually indicate them. This is different from
            Igbo and yoruba which more commonly{' '}
            <br className='hidden md:block' /> uses diacritical marks in formal
            writing.
          </p>
        </div>
      </section>
    </>
  );
};

export default index;
