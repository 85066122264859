import heroBg from '../assets/background.png';
import { FaArrowRight } from 'react-icons/fa6';

const Banner = () => {
  return (
    <main>
      <section
        className='relative container mx-auto'
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh',
        }}>
       
        <div className='mt-10 md:mt-0'>
          <h1 className=' my-auto md:text-5xl text-2xl tracking-wider md:leading-relaxed '>
            Speak{' '}
            <strong>
              Yoruba, Igbo, Hausa <br />-
            </strong>
            Terngi.ai <strong>Translates</strong>
          </h1>
          <p className='mt-6 px-3 md:px-0 text-lg'>
            Terngi.ai seamlessly bridges English with Yoruba, Igbo, and Hausa,
            offering real-time AI <br className='hidden md:block' /> translation
            and cultural insights for speakers of all backgrounds.
          </p>

          <button className='border-2 md:border-black md:bg-transparent bg-black md:text-black text-white md:hover:bg-black md:hover:text-white rounded-2xl md:px-12 px-8 py-3.5 font-semibold mt-14 md:text-xl text-lg mx-auto flex'>
              Get early access{' '}
              <span className='my-auto ms-3'>
                <FaArrowRight />
              </span>
            </button>
            {/* <TooltipExample /> */}
        </div>
      </section>
    </main>
  );
};

export default Banner;
