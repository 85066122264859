import chatIconLeft from '../assets/icons/chat-l.png';
import chatIconRight from '../assets/icons/chat-r.png';
import chatIconRound from '../assets/icons/round-lang.png';
import chatIcon from '../assets/icons/lang-a.png';
import VoiceIcon from '../assets/icons/voiceIcon.png';
import QuestIcon from '../assets/icons/queIcon.png';
import RecordIcon from '../assets/icons/recordIcon.png';
import TransIcon from '../assets/icons/translateIcon.png';
import LangIconBg from '../assets/icons/langIconBg.png';
import LangIcon from '../assets/icons/langIcon.png';
import Frameicon from '../assets/icons/chatIcon.png';

const Core = () => {
  const capabilities = [
    {
      icon: chatIconLeft,
      iconOne: VoiceIcon,
      iconTwo: QuestIcon,
      title: 'Text-to-Speech',
      description:
        'Transforms written text in English, Yoruba, Igbo, or Hausa into natural-sounding speech.',
    },
    {
      icon: chatIconRight,
      iconOne: TransIcon,
      iconTwo: RecordIcon,
      title: 'Speech-to-Text',
      description:
        'Recognizes and adapts tone in both text and voice for nuanced, context-appropriate translations.',
    },
    {
      icon: chatIcon,
      iconOne: LangIconBg,
      iconTwo: LangIcon,
      title: 'Document Translation',
      description:
        'Translates entire documents while preserving their original formatting and structure.',
        largeIcons: true,
    },

    {
      icon: chatIconRound,
      iconOne: Frameicon,
      iconTwo: LangIcon,
      title: 'Localization of content',
      description:
        'Specializes in translating and adapting web content for Nigerian language speakers.',
        largeIcons: true,
    },

  ];

  const getIconClass = (isLarge: boolean | undefined, isMain = false) => {
    if (isMain) {
      return 'w-16 h-16 object-contain';
    }
    return isLarge ? 'w-16 h-16 object-contain mt-20' : 'w-24 h-24 object-contain mt-12';
  };
  return (
    <section className='container mx-auto '>
      <h1 className='text-4xl text-center'>Core Capabilities</h1>
      <div className='grid md:grid-cols-4 mt-10 px-3 md:px-0'>
        {capabilities.map((capability, index) => (
          <div
            key={index}
            className='hover:bg-[#F0FCFF] h-auto rounded-2xl px-5 py-8 group relative'>
            <div>
              <div className='opacity-0 group-hover:opacity-100 '>
                <div className='translate-x-[-30px] group-hover:translate-x-0 transition-all duration-500 absolute top-0'>
                  <img
                    src={capability.iconOne}
                    alt=''
                    className={`${getIconClass(capability.largeIcons )} `}
                  />
                </div>
                <div className='opacity-0 group-hover:opacity-100 absolute -top-12 right-5'>
                  <div className='translate-x-[30px] group-hover:translate-x-0 transition-all duration-500'>
                    <img
                      src={capability.iconTwo}
                      alt=''
                      className={`${getIconClass(capability.largeIcons)} `}
                    />
                  </div>
                </div>
              </div>

              <div className='mt-32 text-center'>
                <img
                  src={capability.icon}
                  alt=''
                  className='w-[1.8rem] mx-auto my-6'
                />
                <h2 className='my-3 text-2xl font-medium'>
                  {capability.title}
                </h2>
                <p className='text-gray-600'>{capability.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Core;
