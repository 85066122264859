import React from 'react';
import costumerImage from '../assets/customer-bg.png';
import connectImage from '../assets/connect-bg.png';
import localizeImage from '../assets/localize-bg.png';
import costumerImageS from '../assets/customer-bgs.png';
import connectImageS from '../assets/connect-bgs.png';
import localizeImageS from '../assets/localize-bgs.png';
import { FaArrowRight } from 'react-icons/fa6';

const Diversity = () => {
  return (
    <main className='container mx-auto px-3 md:px-0'>
      {/* costumer languages */}
      <section className='grid md:grid-cols-2 bg-[#F3F0FF] rounded-3xl py-5 mt-24 text-start'>
        <div className='md:px-14 px-5 flex flex-col justify-center items-baseline md:mt-28 mt-8'>
          <h1 className='md:text-4xl text-2xl font-semibold tracking-wide'>
            Speak Every Customer's <br /> Language
          </h1>
          <p className='md:mt-10 mt-5 text-lg'>
            Transform your business communication, connect with clients and
            partners in Yoruba, Igbo, and Hausa with precision and cultural
            sensitivity.
          </p>
          <button className='border-2 hover:bg-black hover:text-white md:bg-transparent md:text-black md:border-black bg-black text-white rounded-2xl md:px-8 px-6 py-3.5 font-semibold md:mt-14 text-lg flex mt-10 '>
            Get early access{' '}
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>
        </div>
        <div className='flex md:justify-end justify-center mt-10 md:mt-0'>
          <img
            src={costumerImage}
            alt='costumer bg'
            className='w-4/5  hidden md:block'
          />
          <img
            src={costumerImageS}
            alt='costumer bg'
            className='px-3 block md:hidden '
          />
        </div>
      </section>

      {/* connect to nigeria language */}
      <section className='grid md:grid-cols-2 bg-[#FFFBF0] rounded-3xl mt-16 text-start'>
        <div className='md:px-14 px-5 flex flex-col justify-center items-baseline md:mt-28 mt-8'>
          <h1 className='md:text-4xl text-2xl font-semibold tracking-wide'>
            Connect Across <br /> Nigeria's Languages
          </h1>
          <p className='md:mt-10 mt-5 text-lg'>
            Never feel out of place again. Terngi.ai is your personal
            interpreter, helping you engage seamlessly with people from
            different Nigerian backgrounds.
          </p>
          <button className='border-2  hover:bg-black hover:text-white md:bg-transparent md:text-black md:border-black bg-black text-white rounded-2xl md:px-8 px-6 py-3.5 font-semibold md:mt-14 mt-10 text-lg flex '>
            Get early access{' '}
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>
        </div>
        <div className='mt-10 md:mt-0'>
          <img
            src={connectImage}
            alt='connect bg'
            className='w-[55%] mx-auto hidden md:block'
          />
          <img
            src={connectImageS}
            alt='connect bg'
            className='px-2 mx-auto block md:hidden'
          />
        </div>
      </section>

      {/* Localize content with ai */}
      <section className='grid md:grid-cols-2 bg-[#F0FCFF] rounded-3xl mt-16 text-start'>
        <div className='md:px-14 px-5  flex flex-col justify-center items-baseline md:mt-28 mt-8'>
          <h1 className='md:text-4xl text-2xl  font-semibold tracking-wide'>
            Localize Content with AI
            <br /> Precision
          </h1>
          <p className='md:mt-10 mt-5 text-lg'>
            Adapt your content for diverse Nigerian audiences. Terngi.ai ensures
            your message is accurately conveyed, preserving cultural nuances and
            idiomatic expressions in each language.
          </p>
          <button className='border-2 hover:bg-black hover:text-white md:bg-transparent md:text-black md:border-black bg-black text-white rounded-2xl md:px-8 px-6 py-3.5 font-semibold md:mt-14 mt-10 text-lg flex '>
            Get early access{' '}
            <span className='my-auto ms-3'>
              <FaArrowRight />
            </span>
          </button>
        </div>
        <div className='md:pt-20 pt-10 pb-10'>
          <img
            src={localizeImage}
            alt='localize bg'
            className='w-2/3 mx-auto hidden md:block'
          />
          <img
            src={localizeImageS}
            alt='localize bg'
            className='w-11/12 mx-auto block md:hidden '
          />
        </div>
      </section>
    </main>
  );
};

export default Diversity;
